
    import { Component, Vue } from 'vue-property-decorator';
    import { Store } from 'vuex';
    import { readUserProfile } from '@/store/main/getters';

    import { IMark } from '@/interfaces';
    import { readMarks, readMarkFilter } from '@/store/main/getters';
    import { dispatchUpdateMarkFilter } from '@/store/main/actions';

@Component
export default class SearchMarks extends Vue {
    public mark_search: string = '';

    get marks() {                                                           // Получаем из хранилища список товаров
        return readMarks(this.$store);
    }

    public created() {                                                      // На создание страницы
        const markFilter = readMarkFilter(this.$store);                     // Получаем из хранилища markFilter 
        if (markFilter) {                                                   // и заполняем им форму
            this.mark_search = markFilter;
        }
    }

    public async search_marks() {                                           // на кнопку "Искать"
        await dispatchUpdateMarkFilter(this.$store, this.mark_search);      // Сохраняем в хранилище данные из формы
    }

    public copy_name(name) {                                          // на кнопку "Копировать"
        navigator.clipboard.writeText(name);
    }
}
